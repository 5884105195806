<template>
  <div>
    <template v-if="credentialType === 'aws'">
      <v-text-field
        v-model="$v.formData.aws.bucket.$model"
        class="required-field"
        :disabled="disabled"
        :hint="$t('hints.bucket')"
        :label="$t('untranslated.bucket')"
        :error-messages="errors.aws.bucket"
        persistent-hint
        required
        @blur="$v.formData.aws.bucket.$touch()"/>

      <v-text-field
        v-model="$v.formData.aws.key.$model"
        class="required-field"
        :disabled="disabled"
        :hint="$t('hints.key')"
        :label="$t('untranslated.key')"
        :error-messages="errors.aws.key"
        persistent-hint
        required
        @blur="$v.formData.aws.key.$touch()"/>

      <v-select
        v-model="$v.formData.aws.region.$model"
        :disabled="disabled"
        :label="$t('untranslated.region')"
        :error-messages="errors.aws.region"
        :items="awsRegions"
        :menu-props="{ maxHeight: 521 }"
        class="required-field"
        required
        @blur="$v.formData.aws.region.$touch()"/>
    </template>

    <template v-if="credentialType === 'azure_storage'">
      <v-text-field
        v-model="$v.formData.azure_storage.container.$model"
        class="required-field"
        :disabled="disabled"
        :hint="$t('hints.container')"
        :label="$t('untranslated.containerName')"
        :error-messages="errors.azure_storage.container"
        persistent-hint
        required
        @blur="$v.formData.azure_storage.container.$touch()"/>

      <v-text-field
        v-model="$v.formData.azure_storage.blob.$model"
        class="required-field"
        :disabled="disabled"
        :hint="$t('hints.prefix')"
        :label="$t('cloudCostManagement.prefix')"
        :error-messages="errors.azure_storage.blob"
        persistent-hint
        required
        @blur="$v.formData.azure_storage.blob.$touch()"/>

      <v-text-field
        v-model="$v.formData.azure_storage.endpoint.$model"
        :disabled="disabled"
        :hint="$t('hints.endpoint')"
        :label="$t('untranslated.endpoint')"
        :error-messages="errors.azure_storage.endpoint"
        persistent-hint
        @blur="$v.formData.azure_storage.endpoint.$touch()"/>
    </template>

    <template v-if="credentialType === 'gcp'">
      <v-card
        class="gcp-card"
        flat
        rounded="lg">
        <v-card-text>
          <v-select
            v-if="canGetCredentialOptions"
            v-model="$v.formData.gcp.project_id.$model"
            class="required-field pb-4"
            item-text="id"
            item-value="id"
            persistent-hint
            required
            :disabled="disabled || loading"
            :hint="$t('hints.projectId')"
            :items="_.get(credentialOptions, 'projects', [])"
            :label="$t('projectId')"
            :error-messages="errors.gcp.project_id"
            @blur="$v.formData.gcp.project_id.$touch()">
            <!-- TODO: FE#5108 enable this when help center is done -->
            <CyTooltip
              v-if="false"
              slot="append-outer"
              bottom>
              <template #activator="{ on }">
                <v-icon
                  class="help-icon"
                  color="grey"
                  v-on="on"
                  @click="() => { /* open the help center */ }">
                  help_outline
                </v-icon>
              </template>
              {{ $t('needHelp') }}
            </CyTooltip>
          </v-select>

          <v-text-field
            v-else
            :value="gcpProjectId"
            class="pb-4"
            :disabled="disabled"
            :hint="$t('hints.projectId')"
            :label="$t('projectId')"
            persistent-hint/>

          <template v-if="canShow.gcpDataset">
            <v-select
              v-if="canGetCredentialOptions"
              v-model="$v.formData.gcp.dataset.$model"
              class="required-field pb-4"
              item-text="id"
              item-value="id"
              persistent-hint
              required
              :disabled="disabled"
              :items="gcpProjectDatasets"
              :hint="$t('hints.dataset')"
              :label="$t('untranslated.billingDataset')"
              :error-messages="errors.gcp.dataset"
              @blur="$v.formData.gcp.dataset.$touch()">
              <!-- TODO: FE#5108 enable this when help center is done -->
              <CyTooltip
                v-if="false"
                slot="append-outer"
                bottom>
                <template #activator="{ on }">
                  <v-icon
                    class="help-icon"
                    color="grey"
                    v-on="on"
                    @click="() => { /* open the help center */ }">
                    help_outline
                  </v-icon>
                </template>
                {{ $t('needHelp') }}
              </CyTooltip>
            </v-select>

            <v-text-field
              v-else
              :value="gcpProjectDataset"
              class="pb-4"
              :disabled="disabled"
              :hint="$t('hints.dataset')"
              :label="$t('untranslated.billingDataset')"
              persistent-hint/>
          </template>

          <template v-if="canShow.gcpTable">
            <v-select
              v-if="canGetCredentialOptions"
              v-model="$v.formData.gcp.table.$model"
              class="required-field pb-4"
              item-text="id"
              item-value="id"
              persistent-hint
              required
              :items="gcpDatasetTables"
              :disabled="disabled"
              :hint="$t('hints.table')"
              :label="$t('untranslated.billingTable')"
              :error-messages="errors.gcp.table"
              @blur="$v.formData.gcp.table.$touch()">
              <!-- TODO: FE#5108 enable this when help center is done -->
              <CyTooltip
                v-if="false"
                slot="append-outer"
                bottom>
                <template #activator="{ on }">
                  <v-icon
                    class="help-icon"
                    color="grey"
                    v-on="on"
                    @click="() => { /* open the help center */ }">
                    help_outline
                  </v-icon>
                </template>
                {{ $t('needHelp') }}
              </CyTooltip>
            </v-select>

            <v-text-field
              v-else
              :value="gcpDatasetTable"
              :disabled="disabled"
              :hint="$t('hints.table')"
              :label="$t('untranslated.billingTable')"
              persistent-hint/>

            <CyAlert
              v-if="canShow.gcpWarningNotification"
              class="mt-4"
              theme="warning"
              :title="$t('noDatasetFound')"
              :content="$t('noDatasetFoundMessage')"
              no-bottom-margin/>
          </template>
        </v-card-text>

        <v-card-actions class="mx-2 pt-0">
          <div
            v-if="loading"
            class="loading-spinner d-flex align-top">
            <v-progress-circular
              color="grey"
              class="mr-4"
              indeterminate
              size="18"
              width="2"/>
            {{ $t('searchingBigQueryDataset') }}
          </div>
          <CyButton
            v-else-if="canGetCredentialOptions"
            variant="secondary"
            theme="grey"
            icon="autorenew"
            :disabled="disabled"
            @click="setupGCPForm">
            {{ $t('refreshDatasets') }}
          </CyButton>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import REGEX from '@/utils/config/regex'
import { checksPass } from '@/utils/helpers'
import { defaultGCPTableID } from '@/utils/helpers/cloud-cost-management'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyCloudCostManagementFormsAccount',
  props: {
    credentialType: {
      type: String,
      default: 'aws',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    canSave: false,
    formData: {
      aws: {
        bucket: '',
        key: '',
        region: '',
      },
      azure_storage: {
        blob: '',
        container: '',
        endpoint: '',
      },
      gcp: {
        project_id: '',
        dataset: '',
        table: '',
      },
    },
    loading: false,
  }),
  validations () {
    return {
      formData: {
        ...this.formDataValidations,
      },
    }
  },
  computed: {
    ...mapState('organization', {
      cloudProviders: (state) => state.available.cloudProviders,
    }),
    ...mapState('organization/credential', {
      credential: (state) => state.detail,
      credentialOptions: (state) => state.options,
    }),
    awsRegions () {
      return _.find(this.cloudProviders, ({ canonical }) => canonical === 'aws')?.regions || []
    },
    canGetCredentialOptions () {
      const credentialCanonical = this.value?.external_backend?.configuration?.credential_canonical
      return checksPass({
        canDisplayCredential: this.$cycloid.permissions.canDisplay('GetCredential', credentialCanonical),
        canListCredential: this.$cycloid.permissions.canDisplay('ListCredentials'),
      })
    },
    canShow () {
      const { canGetCredentialOptions, gcpProjectDatasets, loading, formData } = this
      return {
        gcpDataset: !loading && !_.isEmpty(formData.gcp?.project_id),
        gcpTable: !loading && !_.isEmpty(formData.gcp?.dataset),
        gcpWarningNotification: !loading && _.isEmpty(gcpProjectDatasets) && canGetCredentialOptions,
      }
    },
    defaultGCPProjectId () {
      const { projects = [] } = this.credentialOptions || {}
      return _(projects).find(({ datasets }) => {
        return _.some(datasets, ({ tables }) => _.some(tables, ({ id }) => _.startsWith(id, defaultGCPTableID)))
      })?.id
    },
    gcpDatasetTable () {
      return this.value?.external_backend?.configuration?.table
    },
    gcpDatasetTables () {
      const { gcpProjectDatasets = [], formData } = this
      return _.find(gcpProjectDatasets, ['id', formData.gcp?.dataset])?.tables || []
    },
    gcpProjectDataset () {
      return this.value?.external_backend?.configuration?.dataset
    },
    gcpProjectDatasets () {
      const { credentialOptions, formData } = this
      return _.find(credentialOptions?.projects, ['id', formData.gcp?.project_id])?.datasets || []
    },
    gcpProjectId () {
      return this.value?.external_backend?.configuration?.project_id
    },
    errors () {
      const check = ({ credentialType, input }) => {
        // to avoid computing $v.formData[credentialType] when validation is non-existing
        if (credentialType !== this.credentialType) return

        const errors = []
        const { $dirty, required, $model } = this.$v.formData[credentialType][input]

        if (!$dirty) return errors
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        // TODO: FE#6428 add blob regex check
        if (input === 'container') {
          const isValidAzureContainerName = REGEX.AZURE_CONTAINER_NAME.test($model)
          !isValidAzureContainerName && errors.push(this.$t('invalidContainerName'))
        }
        if (input === 'key') {
          const isValidAWSKey = REGEX.AWS_VALID_MANIFEST_KEY.test($model) || REGEX.AWS_VALID_KEY.test($model)
          !isValidAWSKey && errors.push(this.$t('invalidAWSKey'))
        }
        return errors
      }
      return {
        aws: (() => ({
          bucket: (() => check({ credentialType: 'aws', input: 'bucket' }))(),
          key: (() => check({ credentialType: 'aws', input: 'key' }))(),
          region: (() => check({ credentialType: 'aws', input: 'region' }))(),
        }))(),
        azure_storage: (() => ({
          container: (() => check({ credentialType: 'azure_storage', input: 'container' }))(),
          blob: (() => check({ credentialType: 'azure_storage', input: 'blob' }))(),
        }))(),
        gcp: (() => ({
          project_id: (() => check({ credentialType: 'gcp', input: 'project_id' }))(),
          dataset: (() => check({ credentialType: 'gcp', input: 'dataset' }))(),
          table: (() => check({ credentialType: 'gcp', input: 'table' }))(),
        }))(),
      }
    },
    formDataValidations () {
      const rawValidations = {
        aws: {
          bucket: { required },
          key: {
            required,
            isValidAWSKey: (val) => !val ? true : (REGEX.AWS_VALID_MANIFEST_KEY.test(val) || REGEX.AWS_VALID_KEY.test(val)),
          },
          region: { required },
        },
        gcp: {
          project_id: { required },
          dataset: { required },
          table: { required },
        },
        azure_storage: {
          blob: { required },
          container: { required },
          endpoint: {},
        },
      }
      return {
        [this.credentialType]: rawValidations[this.credentialType],
      }
    },
  },
  watch: {
    canSave (newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('is-valid', newVal)
    },
    formData: {
      handler () {
        if (!this.$hasDataChanged('formData')) this.$v.$reset()

        this.canSave = checksPass({
          noErrors: !this.$v.$anyError,
          allValid: !this.$v.$invalid,
        })
        this.$emit('change', this.formData?.[this.credentialType])
      },
      deep: true,
    },
  },
  async mounted () {
    await this.setupForm()
  },
  destroyed () {
    this.CLEAR_CE_ERRORS('account')
  },
  methods: {
    ...mapActions('organization/credential', [
      'GET_CREDENTIAL_OPTIONS',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'CLEAR_CE_ERRORS',
    ]),
    populateFormValues () {
      const values = _.pick(this.value?.external_backend.configuration, _.keys(this.formData[this.credentialType]))
      this.$set(this.formData, this.credentialType, values)
    },
    async refreshCredentialOptions () {
      const { canonical: credentialCanonical } = this.credential || {}
      if (!credentialCanonical) return
      this.$emit('is-valid', false)
      this.$toggle.loading(true)
      await this.GET_CREDENTIAL_OPTIONS({ credentialCanonical, service: 'gcp_bigquery' })
      this.$toggle.loading(false)
      this.$emit('is-valid', this.canSave)
    },
    async setupForm () {
      if (this.value) this.populateFormValues()
      if (this.credentialType === 'gcp') await this.setupGCPForm()
      this.$emit('change', this.formData[this.credentialType])
      this.$emit('is-valid', this.canSave)
    },
    async setupGCPForm () {
      await this.refreshCredentialOptions()

      if (_.isEmpty(this.formData.gcp.project_id)) {
        const defaultProjectId = this.defaultGCPProjectId || _.first(this.credentialOptions?.projects)?.id
        this.$set(this.formData.gcp, 'project_id', defaultProjectId)
      }
    },
  },
  i18n: {
    messages: {
      en: {
        hints: {
          container: 'Must be lowercase, starts with letters or numbers, and can contain only letters, numbers and the dash (-) character.',
          dataset: 'BigQuery dataset in which Cloud Billing data is stored.',
          endpoint: 'A custom endpoint for the Azure API (default: "https://{account_name}.blob.core.windows.net/", where the "account_name" is the one from the credential).',
          table: 'Table containing detailed usage cost data.',
          prefix: 'Path composed by export directory plus export name, i.e. "directory/exportName". This must be unique.',
          bucket: 'The AWS bucket containing objects',
          key: 'The S3 Key uniquely identifies an object in a bucket',
          projectId: 'Project used for storing GCP Cloud Billing data.',
        },
        invalidAWSKey: 'Invalid AWS key: accepted format are *-Manifest.json or *-aws-billing-detailed-line-items-with-resources-and-tags-*',
        invalidContainerName: 'Invalid container name',
        needHelp: '...need help?',
        noDatasetFound: 'No billing dataset found.',
        noDatasetFoundMessage: 'Cloud Cost Management uses BigQuery exports of detailed usage cost.<br>Make sure you have this enabled by following <a href="https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#enable-bq-export">these instructions</a>.',
        projectId: 'Project ID',
        refreshDatasets: 'Refresh billing datasets',
        searchingBigQueryDataset: 'Searching your project for existing BigQuery billing datasets.',
      },
      es: {
        hints: {
          container: 'Debe estar en minúsculas, comenzar con letras o números y solo puede contener letras, números y el carácter de guión (-).',
          dataset: 'Conjunto de datos de BigQuery en el que se almacenan los datos de Cloud Billing.',
          endpoint: 'Un endpoint personalizado para la API de Azure (predeterminado: "https://{account_name}.blob.core.windows.net/", donde el "account_name" es el de la credencial).',
          table: 'Tabla que contiene datos detallados de costos de uso.',
          prefix: 'Ruta compuesta por el directorio de exportación más el nombre de exportación, es decir, "directorio/exportName". Esto debe ser único.',
          bucket: 'El bucket de AWS que contiene objetos',
          key: 'La clave S3 identifica de forma única un objeto en un depósito',
          projectId: 'Proyecto utilizado para almacenar datos de facturación de GCP Cloud.',
        },
        invalidAWSKey: 'Llave AWS no válida: los formatos aceptados son *-Manifest.json o *-aws-billing-detailed-line-items-with-resources-and-tags-*',
        invalidContainerName: 'Nombre de contenedor no válido',
        needHelp: '...¿necesita ayuda?',
        noDatasetFound: 'No se encontró ningún conjunto de datos de facturación.',
        noDatasetFoundMessage: 'Cloud Cost Management usa las exportaciones de BigQuery de costo de uso detallado.<br>Se asegure de tener eso habilitado siguiendo <a href="https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#enable-bq-export">estas instrucciones</a>.',
        projectId: 'ID de proyecto',
        refreshDatasets: 'Actualizar conjuntos de datos de facturación',
        searchingBigQueryDataset: 'Buscar en su proyecto conjuntos de datos de facturación de BigQuery existentes.',
      },
      fr: {
        hints: {
          container: 'Doit être en minuscules, commencer par des lettres ou des chiffres et ne peut contenir que des lettres, des chiffres et le tiret (-).',
          dataset: 'Ensemble de données BigQuery dans lequel les données Cloud Billing sont stockées.',
          endpoint: `Un endpoint personnalisé pour l'API Azure (par défaut: "https://{account_name}.blob.core.windows.net/", où le "account_name" est celui de l'identifiant).`,
          table: `Tableau contenant des données détaillées sur les coûts d'utilisation.`,
          prefix: `Chemin composé du répertoire d'exportation plus le nom de l'exportation, c'est-à-dire "directory/exportName". Celui-ci doit être unique.`,
          bucket: 'Le compartiment AWS contenant des objets',
          key: 'La clé S3 identifie de manière unique un objet dans un compartiment',
          projectId: 'Projet utilisé pour stocker les données GCP Cloud Billing.',
        },
        invalidAWSKey: 'Clé AWS non valide : le format accepté est *-Manifest.json ou *-aws-billing-detailed-line-items-with-resources-and-tags-*',
        invalidContainerName: 'Nom de conteneur non valide',
        needHelp: `...besoin d'aide?`,
        noDatasetFound: 'Aucun ensemble de données de facturation trouvé.',
        noDatasetFoundMessage: `Cloud Cost Management utilise les exportations BigQuery du coût d'utilisation détaillé.<br>Assurez-vous que cette option est activée en suivant <a href="https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#enable-bq-export">ces instructions</a>.`,
        projectId: 'ID du projet',
        refreshDatasets: 'Actualiser les ensembles de données de facturation',
        searchingBigQueryDataset: 'Rechercher dans votre projet des ensembles de données de facturation BigQuery existants.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.account__raw-container {
  margin-top: 18px;
}

.accounts-list__item {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.gcp-card {
  padding: 6px 8px 8px;
  border: solid 1px cy-get-color("grey", "light-1");
  background-color: cy-get-color("grey", "light-4");
}

.help-icon {
  margin-right: 3px;
  margin-left: 17px;
}
</style>
